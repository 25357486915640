import React, { useState } from 'react'

import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import withInvite, {
  inviteStateProps,
  inviteDispatchProps,
} from '@sportninja/common/components/Invite'
import { t } from '@sportninja/common/i18n'
import {
  ROLE_TYPE_ID_TO_STRING,
  ROUTES,
} from '@sportninja/common/constants/app'
import { invitationType } from '@sportninja/common/constants/invitationType'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import LoadingSpinner from '../../components/LoadingSpinner'
import Helmet from '../../components/Helmet'
import { FormButton } from '../../components/Form/css'
import Icon from '../../components/Icon'
import Waiver from './Waiver'
// import InvitePicker from './InvitePicker'
// import InviteCreateNew from './InviteCreateNew'

import './index.scss'
import './index-mobile.scss'

const entityType = {
  ORGANIZATION: 'Organization',
  TEAM: 'Team',
  COMPETITION: 'Competition',
  PLAYER: 'Player',
}

export const getAnOrA = (type) => {
  if (
    typeof type === 'string' &&
    type.length > 0 &&
    ['a', 'e', 'i', 'o', 'u'].includes(type[0].toLowerCase())
  ) {
    return 'an'
  }
  return 'a'
}

const INVITE_ALREADY_ACCEPTED = 'This invitation has already been accepted.'

const getRouteDestination = (invitationTypeId, entityId) => {
  try {
    const inviteType = invitationType.find(
      (item) => String(item.id) === String(invitationTypeId)
    )
    if (inviteType) {
      let screen
      switch (inviteType.destination) {
        case entityType.ORGANIZATION:
          screen = ROUTES.ORG_PAGES.replace(/:id/, entityId)
          break
        case entityType.TEAM:
          screen = ROUTES.TEAM_PAGES.replace(/:id/, entityId)
          break
        case entityType.COMPETITION:
          screen = ROUTES.SCHEDULE_PAGES.replace(/:id/, entityId)
          break
        default:
          break
      }
      return screen
    }
  } catch (e) {
    return ROUTES.HOME
  }
}

const Invite = ({
  accepted,
  acceptedNames,
  error,
  // existingUserIdx,
  invitee,
  inviterName,
  // isInviteForSelf,
  // isNewInvite,
  loading,
  managedUsers,
  message,
  onConfirm,
  // onCreate,
  roleTypeId,
  self,
  shouldDisplayWaiver,
  invite,
  acceptedFormat,
  waiverData,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  if (loading.page) return <LoadingSpinner fullScreen />

  const classes = cn('invite-section left flex flex-dir-col', {
    'remove-border': error || accepted || invitee.type !== 'player',
  })

  const removeTheFromName = inviterName.replace(/$the[/s]/i, '')
  const roleName = ROLE_TYPE_ID_TO_STRING?.[roleTypeId]

  return (
    <div className='invite-v2'>
      <Helmet title='Invitation' />
      <div className={classes}>
        <h1 className='invite-title' style={{ whiteSpace: 'pre-line' }}>
          {error
            ? error.title || t('errors:somethingWentWrong')
            : t('Web:joinTheX', {
                x: inviterName,
                interpolation: { escapeValue: false },
              })}
          {error?.title === INVITE_ALREADY_ACCEPTED ? (
            <>
              <Link
                className={cn(
                  'sn-button invite-btn invite-finish-btn is--outline',
                  { 'is-canlan': isCanlan }
                )}
                to={getRouteDestination(
                  error?.invite?.type?.id,
                  error?.invite?.destination?.id
                )}
                title={t('util:capitalize', { text: 'Go to page' })}
              >
                {t('util:capitalize', { text: 'Go to page' })}
              </Link>
            </>
          ) : null}
        </h1>
        <h2 className='invite-sub-title' style={{ marginBottom: '12px' }}>
          {accepted ? (
            <>
              {t('Web:invitationAccepted')}
              <Link
                className={cn(
                  'sn-button invite-btn invite-finish-btn is--outline',
                  { 'is-canlan': isCanlan }
                )}
                to={getRouteDestination(
                  invite?.type?.id,
                  invite?.destination?.id
                )}
                title={t('util:capitalize', { text: t('routeNames:profile') })}
              >
                {t('util:capitalize', { text: 'Go to page' })}
              </Link>
            </>
          ) : error ? (
            error.message
          ) : (
            <>
              <span className='invite-highlight'>
                {acceptedNames.first || invitee.nameFirst}{' '}
                {acceptedNames.last || invitee.nameLast}{' '}
              </span>
              {invitee?.email && (
                <span className='invitee-email'>({invitee.email}) </span>
              )}
              {t('Web:isInvitedToJoin')} {t('common:the')}{' '}
              <span className='invite-highlight'>{removeTheFromName}</span>
              {invitee.type && (
                <>
                  {' '}
                  as {getAnOrA(invitee.type)}{' '}
                  <span className='invite-highlight'>
                    {invitee.type !== 'player' && roleName
                      ? roleName
                      : invitee.type}
                  </span>
                </>
              )}
              {message ? ':' : '.'}
              {message && (
                <div
                  style={{
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    padding: '12px',
                    marginTop: 16,
                  }}
                >
                  <Icon
                    name='comment-alt'
                    fontSize={16}
                    color='rgba(255, 255, 255, 0.5)'
                    style={{ marginRight: 12 }}
                  />
                  {message}
                </div>
              )}
              {managedUsers && (
                <div className='invite-mobile-scroller'>
                  <span className='icon'>
                    <i className='fas fa-angle-down' />
                  </span>
                </div>
              )}
            </>
          )}
        </h2>
        {!error && !accepted && (
          //   invitee.type === 'player' ? (
          //   <InvitePicker
          //     disabled={loading.accept}
          //     isNewInvite={isNewInvite}
          //     isInviteForSelf={isInviteForSelf}
          //     existingUserIdx={existingUserIdx}
          //     managedUsers={managedUsers}
          //     onConfirm={onConfirm}
          //     self={self}
          //   />
          // ) : (
          <div className='accept-button-wrap'>
            {shouldDisplayWaiver && (
              <Waiver
                hasScrolled={hasScrolled}
                setHasScrolled={setHasScrolled}
                waiverData={waiverData}
              />
            )}
            <FormButton
              busy={loading.accept}
              disabled={(shouldDisplayWaiver && !hasScrolled) || loading.accept}
              isSubmit
              className='accept-button'
              onClick={onConfirm.bind(this, self.id)}
              // title={
              //   !hasScrolled
              //     ? 'Read to the bottom of the waiver before joining.'
              //     : ''
              // }
            >
              {t('Web:join')}
            </FormButton>
          </div>
        )}
      </div>
      {/* {!error && !accepted && invitee.type === 'player' && (
        <div className='invite-section right flex flex-dir-col'>
          <InviteCreateNew
            disabled={loading.accept}
            invitee={invitee}
            onCreate={onCreate}
            self={self}
          />
        </div>
      )} */}
    </div>
  )
}

Invite.propTypes = {
  accepted: PropTypes.bool.isRequired,
  acceptedNames: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  existingUserIdx: PropTypes.number.isRequired,
  invitee: PropTypes.object.isRequired,
  inviterName: PropTypes.string.isRequired,
  isInviteForSelf: PropTypes.bool.isRequired,
  isNewInvite: PropTypes.bool.isRequired,
  loading: PropTypes.object.isRequired,
  managedUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
    .isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  self: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
}

export default connect(
  inviteStateProps,
  inviteDispatchProps
)(withInvite(Invite))
