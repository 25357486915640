/** @jsxImportSource @emotion/react */
// @ts-nocheck
import css from '@emotion/css/macro'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import sportninjaLogo from './sportninja_dark.svg'
import emailIcon from './email_icon.svg'
import colors from '@sportninja/common/constants/appColors'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import {
  Spin,
  Radio,
  Select,
  Space,
  Checkbox,
  Input,
  DatePicker,
  InputNumber,
} from 'antd'
import { getAnOrA } from '../InviteV2'
import { Button } from 'src/components/Button'
// import PhoneInput from 'src/components/PhoneInput'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useMobxStore } from 'src/state'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import PhoneInputComponent from 'src/components/PhoneFormComponent'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

function getType(type?: string) {
  switch (type) {
    // Player
    case '1':
      return {
        type: t('util:capitalize', { text: t('common:player') }),
      }

    // Official/Staff
    case '2':
    case '4':
    case '6':
      return {
        type: t('util:capitalize', { text: t('common:user') }),
      }

    case '7':
    case '8':
    case '9':
      return {
        type: t('util:capitalize', { text: t('common:official') }),
      }

    // Team invitation to competition - not used yet
    case '3':
    case '5':
    default:
      return {}
  }
}

type RegisterInputProps = {
  label?: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  hasError?: boolean
  errorMessage?: string
  isPassword?: boolean
  type?: 'text' | 'number'
  min?: number
  max?: number
  addonAfter?: string
}

const RegisterInput = ({
  label = '',
  onChange,
  value,
  placeholder,
  errorMessage = '',
  hasError,
  isPassword,
  type = 'text',
  min,
  max,
  addonAfter,
}: RegisterInputProps) => {
  // const [passwordVisible, setPasswordVisible] = useState(false)

  const Element = isPassword
    ? Input.Password
    : type === 'number'
    ? InputNumber
    : Input

  const rest = type === 'number' ? { min, max, addonAfter } : {}

  return (
    <div
      css={css`
        width: 100%;
        .ant-input {
          background-color: ${colors.HEADER};
        }
      `}
    >
      <p
        css={css`
          margin-bottom: 8px;
          font-size: 14px;
        `}
      >
        {label}
      </p>
      <Element
        value={value}
        onChange={(e: any) => {
          if (type === 'number') {
            onChange(e)
          } else {
            onChange(e.target.value)
          }
        }}
        placeholder={placeholder}
        status={hasError ? 'error' : undefined}
        {...rest}
      />
      <p
        css={css`
          margin-top: 8px;
          color: ${colors.ERROR_LIGHT};
          font-size: 12px;
          opacity: ${hasError ? 1 : 0};
        `}
      >
        {errorMessage || '-'}
      </p>
    </div>
  )
}

type ResponseData = {
  id: string
  email: string
  name_first: string
  name_last: string
  name_full: string | null
  message: string | null
  sender: string
  role_type_id: number
  created_at: string
  sent_at: string | null
  accepted_at: string | null
  type: {
    id: string
    name: string
    name_full: string
  }
  destination: {
    id: string
    name: string
    name_full: string
  }
  show_waiver: boolean
  waiver_content: string | null
  birth_date: string
  player_id: string
  show_underage_workflow: boolean
}

interface Props {
  inviteId: string
  login: (values: { email: string; password: string }) => Promise<void>
  logout: () => Promise<void>
}

export const UnderageInvitation = ({ inviteId, login, logout }: Props) => {
  const {
    me: {
      fetchMe,
      setRegisterSuccess,
      state: { registerSuccess },
    },
  } = useMobxStore()

  const [step, setStep] = useState<number | undefined>(undefined)
  const [data, setData] = useState<ResponseData | null>(null)
  const [loading, setLoading] = useState(true)
  const [stepState, setStepState] = useState<number[]>([])
  const [error, setError] = useState<string | null>(null)
  const [createdUserId, setCreatedUserId] = useState<string | null>(null)

  // STEP 0 STATE
  const [radioOption, setRadioOption] = useState<'yes' | 'no'>('no')
  const [relationship, setRelationship] = useState<string>('')

  // STEP 1 STATE
  const [acceptWaiver, setAcceptWaiver] = useState(false)

  // STEP 2 STATE
  const [playerName, setPlayerName] = useState('')
  const [playerLastName, setPlayerLastName] = useState('')
  const [playerDOB, setPlayerDOB] = useState('')
  const [playerHeightFT, setPlayerHeightFT] = useState('')
  const [playerHeightIN, setPlayerHeightIN] = useState('')
  const [playerWeight, setPlayerWeight] = useState('')
  // const [playerJerseySize, setPlayerJerseySize] = useState('')
  const [playerFieldError, setPlayerFieldError] = useState({
    name_first: '',
    name_last: '',
    birth_date: '',
    height: '',
    weight: '',
  })

  // STEP 3 STATE
  const [userName, setUserName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userDOB, setUserDOB] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [fieldError, setFieldError] = useState({
    name_first: '',
    name_last: '',
    birth_date: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  })

  // STEP 4 STATE
  const [timer, setTimer] = useState(0)

  // STEP 6 STATE
  const history = useHistory()

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [timer])

  const loginUser = useCallback(async () => {
    setStep(6)

    try {
      await logout()
      await login({
        email: userEmail,
        password: password,
      })

      setRegisterSuccess(true)
      setStep(6)

      await delay(2000)
      fetchMe()
      await delay(1000)

      history.push(`${ROUTES.INVITE_V2_ROOT}${inviteId}`)
    } catch (e) {
      console.error(e)
      setError(JSON.stringify(e))
    }
  }, [userEmail, password, createdUserId])

  const onResendEmail = async (email: string) => {
    await req('/signup/resend', {
      method: 'POST',
      body: JSON.stringify({ email }),
      requiresAuth: false,
    })
    setTimer(30)
  }

  const validateFormUsingApi = async (data: any) => {
    try {
      const result = await req('/signup/signup-validate', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return result
    } catch (e) {
      console.error('ERROR', e)
      throw e
    }
  }

  const validateChildFormUsingApi = async (data: any) => {
    try {
      const result = await req('/signup/validate', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return result
    } catch (e) {
      console.error('ERROR', e)
      throw e
    }
  }

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields.name_first) {
      setFieldError((prev) => ({
        ...prev,
        name_first: invalid_fields.name_first,
      }))
    }
    if (invalid_fields.name_last) {
      setFieldError((prev) => ({
        ...prev,
        name_last: invalid_fields.name_last,
      }))
    }
    if (invalid_fields.email) {
      setFieldError((prev) => ({
        ...prev,
        email: invalid_fields.email,
      }))
    }
    if (invalid_fields.birth_date) {
      setFieldError((prev) => ({
        ...prev,
        birth_date: invalid_fields.birth_date,
      }))
    }
    if (invalid_fields.password) {
      setFieldError((prev) => ({
        ...prev,
        password: invalid_fields.password,
      }))
    }
    if (invalid_fields.confirm_password) {
      setFieldError((prev) => ({
        ...prev,
        confirmPassword: invalid_fields.confirm_password,
      }))
    }
  }

  const setCustomPlayerErrors = (invalid_fields: any) => {
    if (invalid_fields['player.name_first']) {
      setPlayerFieldError((prev) => ({
        ...prev,
        name_first: invalid_fields['player.name_first'],
      }))
    }
    if (invalid_fields['player.name_last']) {
      setPlayerFieldError((prev) => ({
        ...prev,
        name_last: invalid_fields['player.name_last'],
      }))
    }
    if (invalid_fields['player.birth_date']) {
      setPlayerFieldError((prev) => ({
        ...prev,
        birth_date: invalid_fields['player.birth_date'],
      }))
    }
    if (invalid_fields['player.weight']) {
      setPlayerFieldError((prev) => ({
        ...prev,
        weight: invalid_fields['player.weight'],
      }))
    }
  }

  const onSubmit = useCallback(async () => {
    setFieldError({
      name_first: '',
      name_last: '',
      birth_date: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    })

    // if (password !== confirmPassword) {
    //   setFieldError((prev) => ({
    //     ...prev,
    //     confirmPassword: 'Passwords do not match',
    //   }))

    //   return
    // }

    const player = {
      name_first: playerName,
      name_last: playerLastName,
      birth_date: playerDOB
        ? dayjs(playerDOB)?.toISOString()?.split('T')[0]
        : null,
      height: Number(`${playerHeightFT}.${playerHeightIN ?? 0}`) / 3.281,
      weight: playerWeight,
      // jersey_size: playerJerseySize,
      id: data?.player_id,
    }

    const body = {
      name_first: userName,
      name_last: userLastName,
      birth_date: userDOB ? dayjs(userDOB)?.toISOString()?.split('T')[0] : null,
      email: userEmail,
      phone: userPhone,
      invitation_id: inviteId,
      password,
      confirm_password: confirmPassword,
    }

    try {
      await validateFormUsingApi({
        ...body,
        player: playerName ? { ...player } : null,
      })
      const res = await req('/signup', {
        method: 'POST',
        body: JSON.stringify({
          ...body,
          player: playerName ? { ...player } : null,
        }),
        requiresAuth: false,
      })

      if (res) {
        setCreatedUserId(res.id)
      }

      if (res && data?.email !== userEmail) {
        setStep(4)
      } else if (res && data?.email === userEmail) {
        await loginUser()
      }
    } catch (e: any) {
      console.error(e)
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        setLoading(false)
        return
      }
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    }
  }, [
    data,
    relationship,
    acceptWaiver,
    playerName,
    playerLastName,
    playerDOB,
    playerHeightFT,
    playerHeightIN,
    playerWeight,
    // playerJerseySize,
    userName,
    userLastName,
    userDOB,
    userEmail,
    userPhone,
    password,
    confirmPassword,
    acceptTerms,
  ])

  const renderStep = useCallback(
    (receivedStep?: number) => {
      switch (receivedStep || step) {
        case 0:
          return (
            <div
              css={css`
                padding-top: 32px;
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                Welcome to Sportninja
              </h1>
              <p
                css={css`
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  margin-top: 32px;
                  line-height: 120%;
                `}
              >
                <span
                  css={css`
                    font-weight: 700;
                  `}
                >
                  {data?.name_first} {data?.name_last}
                </span>{' '}
                is invited to join{' '}
                <span
                  css={css`
                    font-weight: 700;
                  `}
                >
                  {data?.destination?.name_full}
                </span>{' '}
                as {getAnOrA(getType(data?.type?.id).type)}{' '}
                <span
                  css={css`
                    font-weight: 700;
                  `}
                >
                  {getType(data?.type?.id).type}
                </span>
                .
              </p>

              <div
                css={css`
                  margin-top: 32px;
                `}
              >
                <p
                  css={css`
                    font-size: 18px;
                  `}
                >
                  Are you{' '}
                  <span
                    css={css`
                      font-weight: 700;
                    `}
                  >
                    {data?.name_first}
                  </span>
                  ?
                </p>
                <div
                  css={css`
                    margin-top: 16px;
                  `}
                >
                  <Radio.Group
                    defaultValue={radioOption}
                    onChange={(e) => {
                      setRadioOption(e.target.value)
                    }}
                  >
                    <Space direction='vertical'>
                      <Radio value='yes'>{`YES, I'm ${data?.name_first}`}</Radio>
                      <Radio value='no'>{"NO, I'm a parent/guardian"}</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
              {radioOption === 'no' && (
                <div
                  css={css`
                    margin-top: 32px;

                    .ant-select {
                      background-color: ${colors.HEADER};
                    }
                  `}
                >
                  <p>Please select your relationship to {data?.name_first}:</p>
                  <Select
                    css={css`
                      width: 50%;
                      margin-top: 8px;
                      background-color: #27303e;

                      @media (max-width: 768px) {
                        width: 100%;
                      }
                    `}
                    placeholder='Relationship'
                    onChange={(value) => {
                      setRelationship(value)
                    }}
                    value={relationship || undefined}
                    options={[
                      { label: 'Father', value: 'Father' },
                      { label: 'Mother', value: 'Mother' },
                      { label: 'Grandfather', value: 'Grandfather' },
                      { label: 'Grandmother', value: 'Grandmother' },
                      { label: 'Brother', value: 'Brother' },
                      { label: 'Sister', value: 'Sister' },
                      { label: 'Other', value: 'Other' },
                    ]}
                  />
                </div>
              )}
              <div
                css={css`
                  margin-top: 32px;
                  width: 30%;

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                <Button
                  label='Next'
                  onClick={() => {
                    if (radioOption === 'yes') {
                      setStep(5)

                      return
                    }

                    setStep(2)
                    setStepState((prev) => [...prev, 0, 2])
                  }}
                  disabled={radioOption === 'no' && relationship === ''}
                />
              </div>
            </div>
          )
        case 1:
          return (
            <div
              css={css`
                padding-top: 32px;
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                Participant Agreement
              </h1>
              <p
                css={css`
                  font-size: 18px;
                  margin-top: 16px;
                `}
              >
                Before joining, please read and review the following waiver:
              </p>
              <div
                css={css`
                  margin-top: 16px;
                  height: 50vh;
                  background-color: #27303e;
                  border-radius: 8px;
                  border-color: ${colors.SOFT_STEEL};
                  overflow-y: auto;
                  padding: 20px;

                  font-size: 14px;
                  letter-spacing: 1.6px;

                  &::-webkit-scrollbar {
                    width: 0.8em;
                  }

                  &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  }

                  &::-webkit-scrollbar-thumb {
                    background-color: ${colors.SOFT_STEEL};
                  }

                  p {
                    margin: 8px 0;
                  }

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                {data?.waiver_content ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.waiver_content?.replace(/\n/g, '<br />'),
                    }}
                  />
                ) : (
                  <>
                    <p>
                      I agree that the directors, management, and staff, of RG
                      Properties, will not be held responsible for any accidents
                      or loss, however caused. I also agree to release the
                      directors, management and staff of RG Properties, from all
                      claims or damages, which may arise as result of/or by
                      reason of such accidents or loss.
                    </p>
                    <p>
                      I am aware that the league is non-contact, (which refers
                      to no body checking), but this does not rule out the
                      possibility of contact between players either by accident
                      or by rule infractions. I am taking part in all hockey
                      sessions at my own risk and I understand that to be
                      eligible for dental accident insurance, I must wear a
                      mouth guard while playing and that my team must have
                      purchased the optional insurance. If my team has not
                      purchased the optional insurance I am not covered for any
                      insurance claims.
                    </p>
                    <p>
                      I understand and agree that using the Attendance feature
                      in the mobile application to signify that I will be
                      attending an event or game is a legal agreement and any
                      misrepresentation, where by another player attends on your
                      behalf, could result in suspension or expulsion from the
                      league.
                    </p>
                    <p>
                      I agree that participating in a non-contact hockey league
                      still contains risk in terms of catching covid 19 and that
                      I also agree to release the directors, management and
                      staff of RG Properties, from all claims retaining to
                      covid-19.
                    </p>
                    <p>
                      I&apos;m aware that facility protocols for Covid-19 must
                      be followed including, not entering the facility if I feel
                      sick or have a fever, have been traveling out of the
                      country within the past 14 days. Also only entering the
                      facility 15 min prior to game time, I must exit the
                      facility 20 min after the game. keep social distance in
                      the change room as marked and No spitting. And that not
                      following these protocols may result in expulsion from the
                      league without refund.
                    </p>
                    <p>
                      I understand that the email address I have provided with
                      my registration constitutes my signature for the purposes
                      of this agreement.
                    </p>
                  </>
                )}
              </div>
              <div
                css={css`
                  margin-top: 16px;
                `}
              >
                <Checkbox
                  checked={acceptWaiver}
                  onChange={(e) => setAcceptWaiver(e.target.checked)}
                >
                  I have read and agree to the terms and conditions outlined in
                  the waiver.
                </Checkbox>
              </div>
              <div
                css={css`
                  margin-top: 16px;
                  display: flex;
                  gap: 16px;

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                {stepState.length > 0 && (
                  <Button
                    label='Back'
                    variant='secondary'
                    onClick={() => {
                      setStep(0)
                      setStepState(stepState.filter((s) => s !== 1))
                    }}
                  />
                )}
                <Button
                  label='Next'
                  onClick={() => {
                    setStep(data?.show_underage_workflow ? 2 : 3)
                    setStepState((prev) => [
                      ...prev,
                      data?.show_underage_workflow ? 2 : 3,
                    ])
                  }}
                  disabled={!acceptWaiver}
                />
              </div>
            </div>
          )
        case 2:
          return (
            <div
              css={css`
                padding-top: 32px;
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                WELCOME TO SPORTNINJA
              </h1>
              <p
                css={css`
                  margin-top: 16px;
                  font-size: 18px;
                `}
              >
                {`Nice to see you ${data?.name_first}’s ${relationship}, lets review ${data?.name_first}'s Player information:`}
              </p>
              <form
                css={css`
                  margin-top: 32px;

                  @media (max-width: 768px) {
                    width: 100%;
                  }

                  .ant-input-number {
                    width: 100%;
                    height: 40px;
                  }

                  .ant-input-number-input {
                    height: 40px;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    gap: 16px;
                    margin-top: 16px;

                    @media (max-width: 768px) {
                      flex-direction: column;
                    }
                  `}
                >
                  <RegisterInput
                    label='First Name *'
                    value={playerName}
                    onChange={(e) => setPlayerName(e)}
                    hasError={playerFieldError?.name_first !== ''}
                    errorMessage={playerFieldError?.name_first}
                  />
                  <RegisterInput
                    label='Last Name *'
                    value={playerLastName}
                    onChange={(e) => setPlayerLastName(e)}
                    hasError={playerFieldError?.name_last !== ''}
                    errorMessage={playerFieldError?.name_last}
                  />
                </div>
                <div
                  css={css`
                    margin-top: 16px;

                    .ant-picker {
                      background-color: ${colors.HEADER} !important;
                      box-shadow: none !important;
                    }
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                      font-size: 14px;
                    `}
                  >
                    Date of Birth *
                  </p>
                  <DatePicker
                    status={
                      playerFieldError?.birth_date !== '' ? 'error' : undefined
                    }
                    allowClear={false}
                    value={playerDOB ? dayjs(playerDOB) : undefined}
                    css={css`
                      width: 100%;
                    `}
                    onChange={(_, dateString) => setPlayerDOB(dateString)}
                  />
                  <p
                    css={css`
                      margin-top: 8px;
                      color: ${colors.ERROR_LIGHT};
                      font-size: 12px;
                      opacity: ${playerFieldError?.birth_date !== '' ? 1 : 0};
                    `}
                  >
                    {playerFieldError?.birth_date}
                  </p>
                </div>
                <div
                  css={css`
                    margin-top: 16px;
                    display: flex;
                    gap: 16px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      gap: 16px;
                      align-items: flex-end;
                      flex: 1;
                    `}
                  >
                    <RegisterInput
                      label='Height'
                      onChange={(e) => {
                        if (e) {
                          setPlayerHeightFT(String(e))
                        } else {
                          setPlayerHeightFT('')
                        }
                      }}
                      value={playerHeightFT}
                      type='number'
                      hasError={playerFieldError?.height !== ''}
                      errorMessage={playerFieldError?.height}
                      min={0}
                      max={10}
                      addonAfter='ft'
                    />
                    <RegisterInput
                      onChange={(e) => {
                        if (e) {
                          setPlayerHeightIN(String(e))
                        } else {
                          setPlayerHeightIN('')
                        }
                      }}
                      value={playerHeightIN}
                      type='number'
                      min={0}
                      max={12}
                      addonAfter='in'
                    />
                  </div>
                  <div
                    css={css`
                      flex: 1;
                      width: 100%;
                    `}
                  >
                    <RegisterInput
                      label='Weight'
                      value={playerWeight}
                      onChange={(e) => {
                        if (e) {
                          setPlayerWeight(String(e))
                        } else {
                          setPlayerWeight('')
                        }
                      }}
                      type='number'
                      hasError={playerFieldError?.weight !== ''}
                      errorMessage={playerFieldError?.weight}
                      min={0}
                      addonAfter='lbs'
                    />
                  </div>
                </div>
                {/* Removing Jersey Size since its not being used */}
                {/* <div
                  css={css`
                    margin-top: 16px;
                    .ant-select {
                      background-color: ${colors.HEADER};
                    }
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                      font-size: 14px;
                    `}
                  >
                    Jersey Size
                  </p>
                  <Select
                    css={css`
                      width: 100%;
                    `}
                    onChange={(value) => setPlayerJerseySize(value)}
                    value={playerJerseySize}
                    options={[
                      { label: 'XS - Extra Small', value: 'XS' },
                      { label: 'S - Small', value: 'S' },
                      { label: 'M - Medium', value: 'M' },
                      { label: 'L - Large', value: 'L' },
                      { label: 'XL - Extra Large', value: 'XL' },
                    ]}
                  />
                </div> */}
              </form>
              <div
                css={css`
                  margin-top: 32px;
                  display: flex;
                  gap: 16px;

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                {stepState.length > 0 && (
                  <Button
                    label='Back'
                    variant='secondary'
                    onClick={() => {
                      setStep(0)
                      setStepState(stepState.filter((s) => s !== 2))
                    }}
                  />
                )}
                <Button
                  label='Next'
                  onClick={async () => {
                    try {
                      setPlayerFieldError({
                        name_first: '',
                        name_last: '',
                        birth_date: '',
                        height: '',
                        weight: '',
                      })
                      setFieldError({
                        name_first: '',
                        name_last: '',
                        birth_date: '',
                        email: '',
                        phone: '',
                        password: '',
                        confirmPassword: '',
                      })
                      await validateChildFormUsingApi({
                        player: {
                          name_first: playerName,
                          name_last: playerLastName,
                          birth_date: playerDOB
                            ? dayjs(playerDOB)?.toISOString()?.split('T')[0]
                            : null,
                          height:
                            Number(`${playerHeightFT}.${playerHeightIN ?? 0}`) /
                            3.281,
                          weight: playerWeight,
                          id: data?.id,
                          // jersey_size: playerJerseySize,
                        },
                      })
                      const numberRegex = /^[0-9]+$/
                      if (
                        (playerHeightFT !== '' &&
                          !numberRegex.test(playerHeightFT)) ||
                        (playerHeightIN !== '' &&
                          !numberRegex.test(playerHeightIN)) ||
                        (playerHeightFT !== '' && Number(playerHeightFT) < 0) ||
                        (playerHeightIN !== '' && Number(playerHeightIN) < 0)
                      ) {
                        setPlayerFieldError((prev) => ({
                          ...prev,
                          height: 'Height invalid',
                        }))
                        return
                      }

                      if (
                        (playerWeight !== '' &&
                          !numberRegex.test(playerWeight)) ||
                        (playerWeight !== '' && Number(playerWeight) < 0)
                      ) {
                        setPlayerFieldError((prev) => ({
                          ...prev,
                          weight: 'Weight invalid',
                        }))

                        return
                      }

                      setStep(3)
                      setStepState((prev) => [...prev, 3])
                    } catch (e) {
                      if (e?.invalid_fields) {
                        setCustomPlayerErrors(e.invalid_fields)
                        return
                      }
                      const errorMessage = getErrorMessage(e)
                      setError(errorMessage)
                    }
                  }}
                />
              </div>
            </div>
          )
        case 3:
          return (
            <div
              css={css`
                padding-top: 32px;
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                Welcome to Sportninja
              </h1>
              <p
                css={css`
                  font-size: 18px;
                  margin-top: 16px;
                `}
              >
                {"Ok, now let's create your profile:"}
              </p>
              <form
                css={css`
                  margin-top: 32px;

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    gap: 16px;
                    margin-top: 16px;

                    @media (max-width: 768px) {
                      flex-direction: column;
                    }
                  `}
                >
                  <RegisterInput
                    label='Your First Name *'
                    value={userName}
                    onChange={(e) => setUserName(e)}
                    hasError={fieldError?.name_first !== ''}
                    errorMessage={fieldError?.name_first}
                  />
                  <RegisterInput
                    label='Your Last Name *'
                    value={userLastName}
                    onChange={(e) => setUserLastName(e)}
                    hasError={fieldError?.name_last !== ''}
                    errorMessage={fieldError?.name_last}
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    gap: 16px;
                    margin-top: 16px;

                    @media (max-width: 768px) {
                      flex-direction: column;
                    }
                  `}
                >
                  {/* <div
                    css={css`
                      flex: 1;
                    `}
                  >
                    <RegisterInput
                      label='Your Email *'
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      hasError={fieldError?.email !== ''}
                      errorMessage={fieldError?.email}
                    />
                  </div> */}
                  <div
                    css={css`
                      flex: 1;

                      .ant-picker {
                        background-color: ${colors.HEADER} !important;
                        box-shadow: none !important;
                      }
                    `}
                  >
                    <p
                      css={css`
                        margin-bottom: 8px;
                        font-size: 14px;
                      `}
                    >
                      Your Date of Birth *
                    </p>
                    <DatePicker
                      status={
                        fieldError?.birth_date !== '' ? 'error' : undefined
                      }
                      allowClear={false}
                      value={userDOB ? dayjs(userDOB) : undefined}
                      css={css`
                        width: 100%;
                      `}
                      onChange={(_, dateString) => setUserDOB(dateString)}
                    />
                    <p
                      css={css`
                        margin-top: 8px;
                        color: ${colors.ERROR_LIGHT};
                        font-size: 12px;
                        opacity: ${fieldError?.birth_date !== '' ? 1 : 0};
                      `}
                    >
                      {fieldError?.birth_date}
                    </p>
                  </div>
                  {/* <div
                    css={css`
                      flex: 1;

                      .ant-input {
                        background-color: ${colors.HEADER};
                      }
                    `}
                  >
                    <p
                      css={css`
                        margin-bottom: 8px;
                        font-size: 14px;
                      `}
                    >
                      Your Phone Number
                    </p>
                    <PhoneInput
                      value={userPhone}
                      placeholder=''
                      onChange={(e) => setUserPhone(e)}
                    />
                  </div> */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '48%',
                      gap: 8,
                    }}
                  >
                    <style>
                      {`
                        .flag-dropdown {
                          height: 40px!important;
                        }
                        .ant-input {
                          background-color: ${colors.HEADER};
                        }
                      `}
                    </style>
                    <p
                      tabIndex={-1}
                      css={css`
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                        color: ${colors.WHITE};
                      `}
                    >
                      Phone Number
                    </p>
                    <PhoneInputComponent
                      tabIndex={1}
                      namePhone={'phone'}
                      onChangePhone={(e) => setUserPhone(e.target.value)}
                      setPhoneValue={setUserPhone}
                      containerStyle={{
                        width: '100%',
                        height: 40,
                      }}
                      inputStyle={{
                        borderWidth: 1,
                        borderColor: '#3F4753',
                        height: 40,
                        borderRadius: 4,
                        display: 'flex',
                        backgroundColor: colors.HEADER,
                        color: 'rgba(255, 255, 255, 0.85)',
                        fontSize: 16,
                        letterSpacing: 0.74,
                        width: '100%',
                      }}
                      disableDropdown={true}
                      invalid={fieldError?.phone !== ''}
                      invalidMessage={fieldError?.phone}
                    />
                    <div
                      css={css`
                        transition: opacity 0.1s ease-in-out;
                        opacity: ${fieldError?.phone !== '' ? 1 : 0};
                        font-size: 12px;
                        color: ${colors.ERROR_LIGHT_LIGHT};
                      `}
                    >
                      <span>{fieldError?.phone}</span>
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    gap: 16px;
                    margin-top: 16px;

                    @media (max-width: 768px) {
                      flex-direction: column;
                    }

                    .ant-input-password {
                      background-color: ${colors.HEADER};
                    }
                  `}
                >
                  <RegisterInput
                    label='Password *'
                    value={password}
                    onChange={(e) => setPassword(e)}
                    hasError={fieldError?.password !== ''}
                    errorMessage={fieldError?.password}
                    isPassword
                  />
                  <RegisterInput
                    label='Confirm Password *'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e)}
                    hasError={fieldError?.confirmPassword !== ''}
                    errorMessage={fieldError?.confirmPassword}
                    isPassword
                  />
                </div>
                <div
                  css={css`
                    margin-top: 16px;
                    display: flex;
                    gap: 8px;
                    align-items: center;
                  `}
                >
                  <Checkbox
                    // disabled={
                    //   password === '' ||
                    //   confirmPassword === '' ||
                    //   userName === '' ||
                    //   userLastName === '' ||
                    //   userEmail === '' ||
                    //   userDOB === ''
                    // }
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                  <p
                    css={css`
                      margin-top: 6px;
                    `}
                  >
                    I agree to the{' '}
                    <a
                      href='/docs/eula.pdf'
                      target='_blank'
                      css={css`
                        cursor: pointer;
                        color: ${colors.DEFAULT_FLAIR};
                      `}
                    >
                      Terms & Conditions
                    </a>{' '}
                    and the{' '}
                    <a
                      href='/docs/privacy.pdf'
                      target='_blank'
                      css={css`
                        cursor: pointer;
                        color: ${colors.DEFAULT_FLAIR};
                      `}
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </form>
              <div
                css={css`
                  margin-top: 32px;
                  display: flex;
                  gap: 16px;

                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                {stepState.length > 0 && (
                  <Button
                    label='Back'
                    variant='secondary'
                    onClick={() => {
                      if (data?.show_underage_workflow) {
                        setStep(2)
                      }

                      setStepState(stepState.filter((s) => s !== 3))
                    }}
                  />
                )}
                <Button
                  label={`Create ${
                    data?.show_underage_workflow ? 'Your' : ''
                  } User`}
                  onClick={onSubmit}
                  disabled={!acceptTerms}
                />
              </div>
            </div>
          )
        case 4:
          return (
            <div
              css={css`
                padding-top: 32px;
                width: 45%;

                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <img alt='Email Icon' src={emailIcon} />
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                  margin-top: 32px;
                `}
              >
                check your email
              </h1>
              <p
                css={css`
                  font-size: 18px;
                  margin-top: 32px;
                  line-height: 120%;
                `}
              >
                {
                  "Great news! We've sent a confirmation email to your inbox. All you need to do is click the link inside to activate your account."
                }
              </p>
              <p
                onClick={() => (timer > 0 ? {} : onResendEmail(userEmail))}
                css={css`
                  cursor: ${timer > 0 ? 'not-allowed' : 'pointer'};
                  margin-top: 16px;
                  font-size: 18px;
                  line-height: 120%;
                  color: ${timer > 0
                    ? colors.ATTENDANCE_GRAY
                    : colors.DEFAULT_FLAIR};
                `}
              >
                Resend email {timer > 0 && ` (${timer})`}
              </p>
            </div>
          )
        case 5:
          return (
            <div
              css={css`
                padding-top: 32px;
                width: 45%;

                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                Heads Up!
              </h1>
              <p
                css={css`
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  margin-top: 32px;
                  line-height: 120%;
                `}
              >
                Seems like you’re not 13 yet. <br /> Please have a parent or
                guardian create your SportNinja account. Thanks!
              </p>
            </div>
          )
        case 6:
          return (
            <div
              css={css`
                padding-top: 32px;
                width: 30%;

                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <h1
                css={css`
                  font-family: Rift, sans-serif;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 700;
                `}
              >
                Almost there!
              </h1>
              <div
                css={css`
                  margin: 32px 0;
                  display: flex;
                  gap: 16px;
                  align-items: center;
                `}
              >
                <p
                  css={css`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                  `}
                >
                  We are setting up your profile.
                </p>
                <div>
                  <Spin />
                </div>
              </div>
            </div>
          )
        default:
          return <Spin />
      }
    },
    [
      step,
      data,
      radioOption,
      relationship,
      acceptWaiver,
      playerName,
      playerLastName,
      playerDOB,
      playerHeightFT,
      playerHeightIN,
      playerWeight,
      userName,
      userLastName,
      userDOB,
      userEmail,
      userPhone,
      password,
      confirmPassword,
      acceptTerms,
      fieldError,
      playerFieldError,
      timer,
      registerSuccess,
    ]
  )

  useLayoutEffect(() => {
    const fetchInfo = async () => {
      try {
        const response: { data: ResponseData } = await req(
          `/invitations/${inviteId}`
        )

        if (registerSuccess) {
          setStep(6)
        } else {
          setStep(0)
          setPlayerName(response.data.name_first ?? '')
          setPlayerLastName(response.data.name_last ?? '')
          setPlayerDOB(
            dayjs(response.data.birth_date).utc().format('YYYY-MM-DD') ?? ''
          )
          setUserEmail(response.data.email ?? '')
        }

        setData(response.data)
      } catch (e) {
        console.error(e)
        setError(JSON.stringify(e))
      } finally {
        setLoading(false)
      }
    }

    fetchInfo()
  }, [registerSuccess])

  if (loading) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <Spin />
      </div>
    )
  }

  if (!loading && !data) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <p>Finishing...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        `}
      >
        <p>{error}</p>
      </div>
    )
  }

  return (
    <div
      css={css`
        padding: 60px;
        @media (max-width: 768px) {
          padding: 20px;
        }
      `}
    >
      <img src={sportninjaLogo} alt='SportNinja' />
      <div
        css={css`
          display: flex;
          margin-top: 20px;

          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            flex: 1;

            @media (max-width: 768px) {
              border-right: none;
              padding-right: 0;
              margin-right: 0;
              min-height: 0;

              margin-bottom: 32px;
            }
          `}
        >
          {renderStep(step)}
        </div>

        {step !== 4 && step !== 5 && step !== 6 && (
          <div
            css={css`
              flex: 1;
              padding-left: 16px;
              margin-left: 16px;
              border-left: 1px solid ${colors.SOFT_STEEL};
              min-height: 60vh;

              @media (max-width: 768px) {
                text-align: center;
                border-left: none;
                padding-left: 0;
                margin-left: 0;
                min-height: 0;
              }
            `}
          >
            <p>
              Or if you already have a SportNinja <br /> account{' '}
              <span
                onClick={() => {
                  history.push(`${ROUTES.INVITE_V2_ROOT}${inviteId}`)
                }}
                css={css`
                  cursor: pointer;
                  color: ${colors.DEFAULT_FLAIR};
                  line-height: 150%;

                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                log in
              </span>{' '}
              to accept the invite.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
