/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Checkbox } from 'antd'
import React from 'react'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

type CheckBoxInputProps = {
  label: string
  disabled: boolean
  name: string
  value: boolean
  onChange: (e: CheckboxChangeEvent) => void
  fieldErrors: any
  customError: any
}

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  label,
  disabled,
  name,
  value,
  onChange,
  fieldErrors,
  customError,
}) => {
  return (
    <>
      <Checkbox
        disabled={disabled}
        onChange={(e) => onChange(e)}
        checked={value}
        css={css`
          width: 100%;
        `}
      />
      <p
        css={css`
          ${paragraphSmall}
          ${weightRegular}
                color: ${colors.WHITE};
          align-self: stretch;
        `}
      >
        {label}
      </p>
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
