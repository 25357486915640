/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Radio, message, Upload, Select, Alert, Space } from 'antd'
import {
  LoadingOutlined,
  PictureOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '../Button'
import { Input } from '../Input'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import banners from '../Form/banner-helpers'
import { createOrg, editOrg } from 'src/helpers/EntityCreationHelper'

import DeleteModal from '../Modal/DeleteModal'
import Icon from '../Icon'
import req from '@sportninja/common/api/request'
import { Tabs } from 'antd'
import ToggleSection from 'src/pages/Schedule/EditRegistration/ToggleSection'
import CopyComponent from '../CopyComponent'
import './styles.scss'
import IntegrationsForm from './IntegrationsForm'
import { heading6, weightSemiBold } from '../css'

const labelById = {
  0: 'General',
  1: 'Settings',
  2: 'Integrations',
}

const NewOrgForm = ({
  initialState = null,
  onCancel,
  triggerRefresh,
  parentOrgId,
  reloadPage = false,
  account,
  fetchAccount,
  readItem,
  orgModalRef,
}) => {
  const [mode, setMode] = useState('left')
  const [value, setValue] = useState(initialState?.organization_name || '')
  const [isLoading, setIsLoading] = useState(false)
  const [privacySettings, setPrivacySettings] = useState('public')
  const [imageUrl, setImageUrl] = useState(
    initialState?.image?.full_path || null
  )
  const [imageChanged, setImageChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const isEdit = initialState?.name_full ? true : false
  const [isWaiverEnabled, setIsWaiverEnabled] = useState(
    initialState?.show_waiver || false
  )
  const [waiver, setWaiver] = useState(initialState?.waiver_id || null)
  const [waivers, setWaivers] = useState([])
  const [waiverError, setWaiverError] = useState(null)

  const handleModeChange = (e) => {
    setMode(e.target.value)
  }

  const {
    control,
    setValue: setFormValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      organization_name: initialState?.name_full || '',
      abbreviation: initialState?.abbreviation || '',
      phone: initialState?.phone || '',
      website: initialState?.website || '',
      email: initialState?.email || '',
      privacy_settings:
        initialState?.is_public === true
          ? 'public'
          : initialState?.is_public === false
          ? 'private'
          : 'public',
    },
  })

  const onError = (error) => {
    if (error?.abbreviation) {
      setError('Abbreviation is required.')
    }
    if (error?.organization_name) {
      setError('Organization name is required.')
    }
    // if (error?.website) {
    //   if (error?.website?.type === 'pattern') {
    //     setError('Invalid website url.')
    //   }
    // }
    if (error?.phone) {
      if (error?.phone?.type === 'pattern') {
        setError('Invalid phone number.')
      }
    }
    if (error?.email) {
      if (error?.email?.type === 'pattern') {
        setError('Invalid email.')
      }
    }
  }

  const onSubmitPressed = async (data) => {
    try {
      setIsLoading(true)
      setError(null)
      setWaiverError(null)
      if (!data) {
        setError('Please fill out all required fields.')
        return
      }
      if (data?.abbreviation?.length > 8) {
        setError('Abbreviation must be less than 8 characters.')
        return
      }
      if (data?.email?.length > 0) {
        const email = data?.email
        if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
          setError('Invalid email.')
          return
        }
      }
      if (isWaiverEnabled && !waiver) {
        setWaiverError('Please select a waiver.')
        setError('Please select a waiver.')
        return
      }
      let response
      if (isEdit) {
        response = await editOrg(
          {
            ...data,
            waiver_id: isWaiverEnabled ? waiver : null,
            show_waiver: isWaiverEnabled,
          },
          imageUrl,
          initialState?.id,
          imageChanged
        )
      } else {
        response = await createOrg(
          {
            ...data,
            waiver_id: isWaiverEnabled ? waiver : null,
            show_waiver: isWaiverEnabled,
          },
          imageUrl,
          parentOrgId
        )
      }
      // First dismiss the modal
      onCancel()
      // Show the success message
      if (isEdit) {
        banners.entityUpdate(ENTITY_TYPES.org)(response)
      } else {
        banners.entityCreate(ENTITY_TYPES.org)(response)
      }
      if (!isEdit) {
        // Reload the page
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      } else {
        triggerRefresh()
        orgModalRef?.current?.closeModal()
      }
    } catch (e) {
      console.error(e)
      const errorMessage = e?.invalid_fields
        ? getErrorMessage(e?.invalid_fields)
        : e?.message || 'Something went wrong'
      setError(errorMessage)
      setWaiverError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancelPressed = () => {
    if (value && !initialState) {
      setValue('')
      setFormValue('organization_name', '')
      setFormValue('abbreviation', '')
      setFormValue('phone', '')
      setFormValue('website', '')
      setFormValue('email', '')
      setFormValue('public')
    } else {
      onCancel()
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    if (img) {
      reader.readAsDataURL(img)
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
      setError('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
      setError('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info) => {
    try {
      setImageChanged(true)
      setLoading(true)
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    } finally {
      setLoading(false)
    }
  }

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PictureOutlined
          style={{
            fontSize: 16,
            color: colors.ATTENDANCE_GRAY,
          }}
        />
      )}
      <div
        style={{
          marginTop: 8,
          color: colors.ATTENDANCE_GRAY,
          fontSize: 12,
        }}
      >
        Upload
      </div>
    </div>
  )

  const GeneralSettingsForm = () => {
    return (
      <div
        css={css`
          padding-top: 16px;
          padding-left: 16px;
          padding-right: 16px;
          display: flex;
          flex: 1;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          max-height: 525px;
        `}
      >
        {/* Title */}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 16px;
          `}
        >
          <p
            css={css`
              ${heading6}
              ${weightSemiBold}
             color: ${colors.WHITE};
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed'};
            `}
          >
            General Info
          </p>
          {isEdit ? (
            <div>
              <CopyComponent id={initialState?.id} />
            </div>
          ) : null}
        </div>
        <form
          onSubmit={handleSubmit(onSubmitPressed, onError)}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
          `}
        >
          <style>
            {`
          .ant-input {
            background-color: ${colors.HEADER};
          }
          :where(.css-dev-only-do-not-override-11bjtgs).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, :where(.css-dev-only-do-not-override-11bjtgs).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
            background-color: ${colors.HEADER};
            width: 90px;
            height: 90px;
          }
        `}
          </style>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex: 1;
              gap: 16px;
              margin-bottom: 8px;
            `}
          >
            <Upload
              name='avatar'
              listType='picture-circle'
              className='avatar-uploader'
              showUploadList={false}
              action={null}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              css={css`
                display: flex;
                flex: 0.2;
                border-color: ${colors.DEFAULT_FLAIR};
              `}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt='avatar'
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            {isEdit && imageUrl ? (
              <CloseCircleOutlined
                onClick={() => {
                  setImageUrl(null)
                }}
                style={{
                  color: colors.FIERY_RED,
                  fontSize: 24,
                }}
              />
            ) : null}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;
            `}
          >
            <Input
              fieldName='organization_name'
              control={control}
              label='Organization Name'
              required={true}
            />
            <Input
              fieldName='abbreviation'
              control={control}
              label='Abbreviation (8 or less characters)'
              required={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;
            `}
          >
            <Input
              fieldName='phone'
              control={control}
              label='Phone'
              type='tel'
              customRules={{
                pattern: {
                  value: /^\+?[1-9]\d{1,14}$/,
                  message: 'Invalid phone number',
                },
                minLength: 5,
              }}
            />
            <Input
              fieldName='website'
              control={control}
              label='Website'
              // type='url'
              // customRules={{
              //   pattern: {
              //     value: /^(ftp|http|https):\/\/[^ "]+$/i,
              //     message: 'Invalid url',
              //   },
              // }}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;
            `}
          >
            <Input
              fieldName='email'
              control={control}
              label='Contact Email'
              type='email'
              customRules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email',
                },
              }}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 12px;
              margin-bottom: 8px;
              flex-direction: column;
            `}
          >
            <p
              tabIndex={-1}
              css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: ${colors.WHITE};
              `}
            >
              Visibility
            </p>
            <Controller
              name='privacy_settings'
              control={control}
              defaultValue='public'
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Radio.Group
                    {...field}
                    defaultValue={'public'}
                    onChange={(e) => {
                      setPrivacySettings(e.target.value)
                      field.onChange(e)
                    }}
                  >
                    <Radio.Button value='public'>Public</Radio.Button>
                    <Radio.Button value='private'>Private</Radio.Button>
                  </Radio.Group>
                )
              }}
            />
            {privacySettings && privacySettings === 'public' ? (
              <p
                tabIndex={-1}
                css={css`
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 12px;
                  color: ${colors.WHITE};
                  margin-bottom: 8px;
                `}
              >
                Everyone can search, view details, and follow your Organization.
              </p>
            ) : privacySettings && privacySettings === 'private' ? (
              <p
                tabIndex={-1}
                css={css`
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 12px;
                  color: ${colors.WHITE};
                  margin-bottom: 8px;
                `}
              >
                Private Organizations can only be viewed and followed by people
                that have been invited by admins.
              </p>
            ) : null}
          </div>
          {error ? (
            <Space direction='vertical' style={{ width: '100%' }}>
              <Alert
                message='Error'
                description={error || 'An error occurred. Please try again.'}
                type='error'
                showIcon
              />
            </Space>
          ) : null}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              position: sticky;
              bottom: 0;
              background-color: ${colors.HEADER};
              z-index: 100;
              padding-bottom: 16px;
              padding-top: 16px;
            `}
          >
            <Button
              onClick={(e) => {
                e.preventDefault()
                onCancelPressed()
              }}
              label={value && !initialState ? 'Clear' : 'Cancel'}
              variant='secondary'
              disabled={isLoading}
            />
            <Button
              onClick={handleSubmit(onSubmitPressed, onError)}
              type='submit'
              label='Submit'
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    )
  }

  const WaiverForm = () => {
    const fetchData = useCallback(async () => {
      try {
        const { data } = await req(`/account/${account?.uid}/waiver/`)
        if (data) {
          const updatedData = data.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))
          setWaivers(updatedData)
        }
      } catch (e) {
        console.error(e)
      }
    }, [account?.uid, setWaivers])

    useEffect(() => {
      if (account?.uid) {
        fetchData()
      }
    }, [account])

    return (
      <div
        css={css`
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1;
          height: 100%;
        `}
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          {/* Title */}
          <p
            css={css`
              ${heading6}
              ${weightSemiBold}
                color: ${colors.WHITE};
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed'};
              margin-bottom: 16px;
            `}
          >
            Waiver
          </p>
          {/* Waiver Checkbox */}
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              align-items: center;
              justify-content: space-between;
            `}
          >
            {/* Text's */}
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <span
                css={css`
                  color: #fff;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 145%; /* 20.3px */
                `}
              >
                Request Waiver
              </span>
              <span
                css={css`
                  color: #fff;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 145%; /* 17.4px */
                `}
              >
                Player will be asked to sign the waiver mandatory.
              </span>
            </div>
            <ToggleSection
              title=''
              description=''
              isEnabled={isWaiverEnabled}
              onClick={() => {
                setIsWaiverEnabled((o) => !o)
              }}
            />
          </div>
          {/* Separator */}
          <div
            css={css`
              border-bottom: 1px solid #3f4753;
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          />
          {/* Select Waiver */}
          {isWaiverEnabled ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <span
                  css={css`
                    color: #fff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 145%; /* 20.3px */
                  `}
                >
                  Select Waiver
                </span>
                <span
                  css={css`
                    color: #fff;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 145%; /* 20.3px */
                  `}
                >
                  (Create Waivers in Account Settings)
                </span>
              </div>
              <div
                css={css`
                  .ant-select {
                    background-color: ${colors.HEADER};
                    width: 100%;
                  }
                `}
              >
                <Select
                  showSearch={false}
                  onChange={(e) => {
                    setWaiver(e)
                  }}
                  options={waivers}
                  value={waiver}
                />
              </div>
              {waiverError ? (
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Alert
                    message='Error'
                    description={
                      waiverError || 'An error occurred. Please try again.'
                    }
                    type='error'
                    showIcon
                  />
                </Space>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 20px;
            align-self: flex-end;
            width: 100%;
          `}
        >
          <Button
            onClick={(e) => {
              e.preventDefault()
              onCancelPressed()
            }}
            label={value && !initialState ? 'Clear' : 'Cancel'}
            variant='secondary'
            disabled={isLoading}
          />
          <Button
            onClick={() => {
              const originalObject = control._fields
              // We need to transform the object to get the values
              const newObject = Object.fromEntries(
                Object.entries(originalObject).map(([key, value]) => [
                  key,
                  value._f.value,
                ])
              )
              onSubmitPressed(newObject)
            }}
            type='submit'
            label='Submit'
            isLoading={isLoading}
          />
        </div>
      </div>
    )
  }

  const Integrations = () => {
    return (
      <IntegrationsForm
        onCancelPressed={onCancelPressed}
        onSubmitPressed={onSubmitPressed}
        orgId={initialState?.id}
      />
    )
  }

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            padding-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              align-items: center;
            `}
          >
            <h1
              css={css`
                font-size: 32px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                font-weight: 600;
                color: ${colors.WHITE};
                margin-left: 16px;
              `}
            >
              {isEdit ? 'Organization Details' : 'Create a New Organization'}
            </h1>
            {isEdit ? (
              <DeleteModal
                title='Delete Organization'
                message={`Are you sure you want to delete ${initialState?.name_full}? This cannot be undone.`}
                onDelete={async (shouldForce = false) => {
                  const query = {}
                  if (shouldForce) {
                    query.force = 1
                  }
                  await req(`/${ENTITY_TYPES.org}/${initialState?.id}`, {
                    method: 'DELETE',
                    query,
                  })
                  window.location.reload()
                }}
                Button={({ ...props }) => (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      pointer-events: auto;
                      cursor: pointer;
                      border: 1px solid ${colors.PRIMARY};
                      border-radius: 36px;
                      width: 36px;
                      height: 36px;
                      padding: 8px;
                    `}
                    {...props}
                  >
                    <button>
                      <Icon faType='far' name='trash' fontSize={16} />
                    </button>
                  </div>
                )}
                useForceDelete={true}
              />
            ) : null}
          </div>
        </div>
        <button
          onClick={() => onCancel()}
          css={css`
            position: fixed;
            right: 40px;
            top: 40px;
            background: none;
            border: none;
          `}
        >
          <CloseOutlined
            fontSize={20}
            style={{
              color: colors.WHITE,
              fontSize: 20,
            }}
          />
        </button>
        <Tabs
          defaultActiveKey='0'
          tabPosition={'left'}
          style={css`
            display: flex;
            flex: 1;
            height: 100%;
          `}
          items={new Array(3).fill(null).map((_, i) => {
            const id = String(i)
            return {
              label: labelById[id],
              key: id,
              children:
                id === '0'
                  ? GeneralSettingsForm()
                  : id === '1'
                  ? WaiverForm()
                  : Integrations(),
            }
          })}
        />
      </div>
    </>
  )
}

export default NewOrgForm
