import { call, put } from 'redux-saga/effects'

import actions from '../actions/invite'

import { readFactory } from './helpers/read'

import req from '@sportninja/common/api/request'
import { ENTITY_TYPES, generateSagas } from './utils'
const ENTITY_TYPE = ENTITY_TYPES.invite

const api = {
  accept: async (id, body) =>
    await req(`/invitations/${id}/accept`, { method: 'POST', body }),
}

const invite = [
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [
    actions.accept,
    function* (payload) {
      const { id, user_id, accepted_format } = payload
      const body = JSON.stringify({ user_id, accepted_format })
      const response = yield call(api.accept, id, body)

      yield put(actions.accept.success({ id }))
      return response
    },
  ],
]

export default generateSagas([...invite])
