import { generateActions } from './utils'

// An individual invitation
const invitationDefinition = {
  read: ['id'],
  accept: ['id', 'user_id', 'accepted_format'],
}

const invitationActions = generateActions(['INVITE'], invitationDefinition)

export default invitationActions
