/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useRef, useState } from 'react'

const WAIVER_HEIGHT = 300

const Waiver = ({ hasScrolled, setHasScrolled, waiverData }) => {
  const divRef = useRef(null)
  const [divHeight, setDivHeight] = useState(0)

  const onScroll = (event) => {
    if (hasScrolled) return

    if (
      event.currentTarget.scrollTop + WAIVER_HEIGHT >=
      event.currentTarget.scrollHeight
    ) {
      setHasScrolled(true)
    }
  }

  useEffect(() => {
    if (divRef.current) {
      setDivHeight(divRef.current.offsetHeight)
    }
  }, [waiverData]) // Recalculate height whenever waiverData changes

  useEffect(() => {
    if (divHeight && divHeight > 0) {
      if (divHeight <= WAIVER_HEIGHT) {
        setHasScrolled(true)
      }
    }
  }, [divHeight]) // Recalculate height whenever divHeight changes

  return (
    <>
      <div
        css={css`
          margin-top: 20px;
          font-size: 15px;
        `}
      >
        Please read and agree to the following waiver before joining:
      </div>
      <div
        onScroll={onScroll}
        css={css`
          line-height: 17px;
          font-size: 14px;
          letter-spacing: 1.6px;
          height: ${WAIVER_HEIGHT}px;
          overflow: auto;
          border: 2px solid white;
          border-radius: 3px;
          padding: 4px 16px;
          scrollbar-color: white;
          margin-top: 12px;

          p {
            margin: 8px 0;
          }
        `}
      >
        {waiverData ? (
          <div
            ref={divRef}
            dangerouslySetInnerHTML={{
              __html: waiverData?.replace(/\n/g, '<br />'),
            }}
          />
        ) : (
          <>
            <p>
              I agree that the directors, management, and staff, of RG
              Properties, will not be held responsible for any accidents or
              loss, however caused. I also agree to release the directors,
              management and staff of RG Properties, from all claims or damages,
              which may arise as result of/or by reason of such accidents or
              loss.
            </p>
            <p>
              I am aware that the league is non-contact, (which refers to no
              body checking), but this does not rule out the possibility of
              contact between players either by accident or by rule infractions.
              I am taking part in all hockey sessions at my own risk and I
              understand that to be eligible for dental accident insurance, I
              must wear a mouth guard while playing and that my team must have
              purchased the optional insurance. If my team has not purchased the
              optional insurance I am not covered for any insurance claims.
            </p>
            <p>
              I understand and agree that using the Attendance feature in the
              mobile application to signify that I will be attending an event or
              game is a legal agreement and any misrepresentation, where by
              another player attends on your behalf, could result in suspension
              or expulsion from the league.
            </p>
            <p>
              I agree that participating in a non-contact hockey league still
              contains risk in terms of catching covid 19 and that I also agree
              to release the directors, management and staff of RG Properties,
              from all claims retaining to covid-19.
            </p>
            <p>
              I&apos;m aware that facility protocols for Covid-19 must be
              followed including, not entering the facility if I feel sick or
              have a fever, have been traveling out of the country within the
              past 14 days. Also only entering the facility 15 min prior to game
              time, I must exit the facility 20 min after the game. keep social
              distance in the change room as marked and No spitting. And that
              not following these protocols may result in expulsion from the
              league without refund.
            </p>
            <p>
              I understand that the email address I have provided with my
              registration constitutes my signature for the purposes of this
              agreement.
            </p>
          </>
        )}
      </div>
      <div
        css={css`
          margin-top: 12px;
          font-size: 15px;
        `}
      >
        Read to the bottom of the waiver before joining.
      </div>
    </>
  )
}

export default Waiver
